import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header class="App-header">

      <h2>🧀Cheese Re:Dive🧀</h2>
      <h2>台版公主連結 未來卡池推薦</h2>

    <br></br>
      
      <hr style={{ width: '60%' }} />
      <br></br>


        <h3>✨「帆希（新年）」✨</h3>
        <h5>🔔可以看到他常常出現，但必要性並不大</h5>
        <img  class = "img" src = "https://truth.bahamut.com.tw/s01/202301/903b354c515dc303169e4fb0bf765e43.JPG?w=1000" width="40%" height= "40%"></img>
        <p></p>
        <hr style={{ width: '40%' }} />
        <p></p>



        <h3>✨新年復刻一番賞池✨</h3>
        <h5>🔔「新年似似花」必抽，「新年黑貓」沒有的話也可以考慮，戰對戰出場率有再慢慢變少</h5>
        <h5>🔔「新年黑貓」沒有的話也可以考慮，沒有的話影響也沒有這麼大了</h5>
        <img  class = "img" src = "https://truth.bahamut.com.tw/s01/202301/3cafea7551726afd5269f5be417b5277.JPG?w=1000" width="40%" height= "40%"></img>
        <p></p>
        <hr style={{ width: '40%' }} />
        <p></p>



        <h3>✨「望（解放者）」✨</h3>
        <h5>🔔出場率高，所有玩家必抽</h5>
        <img  class = "img" src = "https://truth.bahamut.com.tw/s01/202302/2103a7a5c416e0fb5e7fdedcf402ed0c.JPG?w=1000" width="40%" height= "40%"></img>
        <p></p>
        <hr style={{ width: '40%' }} />
        <p></p>


        <h3>✨常駐自選一番賞池✨</h3>
        <h5>🔔沒有「舞台美咲」or「體操服優妮」可以抽</h5>
        <img  class = "img" src = "https://truth.bahamut.com.tw/s01/202302/b4452bcdb3229d36ad34ddb1d2950850.JPG?w=1000" width="40%" height= "40%"></img>
        <p></p>
        <hr style={{ width: '40%' }} />
        <p></p>


        <h3>✨常駐自選一番賞池✨</h3>
        <h5>🔔沒有「探險真步」、「探險熊槌」、「泳裝鏡華」、「聖誕黑騎」、「野性跳跳虎」以上任何一隻在考慮抽</h5>
        <img  class = "img" src = "https://truth.bahamut.com.tw/s01/202302/b4ca36f4d0c3410b65d2f7063356748a.JPG?w=1000" width="40%" height= "40%"></img>
        <p></p>
        <hr style={{ width: '40%' }} />
        <p></p>


        <h3>✨「矛依未（解放者）」✨</h3>
        <h5>🔔戰對戰玩家必抽，其餘玩家抽起來也不虧</h5>
        <h5>❗此角色會召喚男人，在公連裡看到男人會感到嚴重不適的玩家絕對不要抽</h5>
        <img  class = "img" src = "https://truth.bahamut.com.tw/s01/202302/23f1f6666c78f381d081fe6c1f002269.JPG?w=1000" width="40%" height= "40%"></img>
        <p></p>
        <hr style={{ width: '40%' }} />
        <p></p>


        <h3>✨自選復刻一番賞池✨</h3>
        <h5>🔔「超載配可」戰對戰玩家可以抽起來備用，不抽也還好</h5>
        <h5>🔔「超載黑貓」競技場玩家可以抽，其餘都不用抽</h5>
        <img  class = "img" src = "https://truth.bahamut.com.tw/s01/202302/44b42b654f8fb1355c9b7d9b3456d36c.JPG?w=1000" width="40%" height= "40%"></img>
        <p></p>
        <hr style={{ width: '40%' }} />
        <p></p>



        <h3>✨「怜（泳裝）」✨</h3>
        <h5>🔔所有玩家都不用抽</h5>
        <img  class = "img" src = "https://truth.bahamut.com.tw/s01/202302/c86e96f46c01460116364041426763fb.JPG?w=1000" width="40%" height= "40%"></img>
        <p></p>
        <hr style={{ width: '40%' }} />
        <p></p>



        <h3>✨「美空」✨</h3>
        <h5>🔔暫不評論</h5>
        <img  class = "img" src = "https://truth.bahamut.com.tw/s01/202303/a253ac655d8fb1c9ca5299407d8bd2d8.JPG?w=1000" width="40%" height= "40%"></img>
        <p></p>
        <hr style={{ width: '40%' }} />
        <p></p>


        <h3>✨「珠希（咖啡廳）」✨</h3>
        <h5>🔔暫不評論</h5>
        <img  class = "img" src = "https://truth.bahamut.com.tw/s01/202303/2d5abb4e8d9c4a033559544c65920e79.JPG?w=1000" width="40%" height= "40%"></img>
        <p></p>
        <hr style={{ width: '40%' }} />
        <p></p>
        
        
        <h3>✨「莫妮卡（咖啡廳）」✨</h3>
        <h5>🔔暫不評論</h5>
        <img  class = "img" src = "https://truth.bahamut.com.tw/s01/202303/f7370e0e3c379de55713eec9d38917f8.JPG?w=1000" width="40%" height= "40%"></img>
        <p></p>
        <hr style={{ width: '40%' }} />
        <p></p>
        


      <p></p>

        <h3>
          <i class="fa-solid fa-star icon-star"></i>
          已經是最底囉~ q(≧▽≦q)
        </h3>

      <p></p>
      <p></p>

      </header>
      
    </div>

    
  );
}


export default App;
